import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { rideTheme } from '../components/theme/ride';
import { ResetCSS } from '../components/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../components/Ride/ride.style';
import { DrawerProvider } from '../components/contexts/DrawerContext';
import Navbar from '../components/Ride/Navbar';
import Banner from '../components/Ride/Banner';
// import RideOption from '../components/Ride/RideOption';
// import LocationSection from '../components/Ride/LocationSelection';
// import FeatureSection from '../components/Ride/Feature';
// import LatestNewsSection from '../components/Ride/LatestNews';
// import HowItWorkSection from '../components/Ride/HowItWorks';
// import TestimonialSection from '../components/Ride/TestimonialSection';
// import FeatureSlider from '../components/Ride/FeatureSlider';
// import Footer from '../components/Ride/Footer';

export default () => {
  return (
    <ThemeProvider theme={rideTheme}>
      <Fragment>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner />
          {/* <RideOption />
          <LocationSection />
          <FeatureSlider />
          <FeatureSection />
          <LatestNewsSection />
          <HowItWorkSection />
          <TestimonialSection /> */}
          {/* <Footer /> */}
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
